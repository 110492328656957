export default {
    // Global
    global: {
        back: 'zurück',
        next: 'weiter',
        loading: 'Lädt …',
        pageTitle: 'Willkommen',
        pageTitleTemplate: '%s — Portal',
        change: 'Ändern',
        changeRequest: 'Ändern lassen',
        more: 'mehr',
        blogMore: 'Mehr erfahren',
        details: 'Details',

        states: {
            cancelled: 'Annulliert',
            created: 'In Vorbereitung',
            readyForPickup: 'Zur Abholung',
            pickedUp: 'Abgeholt',
            inDelivery: 'In Zustellung',
            delivered: 'Persönlich zugestellt',
        },

        days: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag',
        },

        daysShort: {
            monday: 'Mo',
            tuesday: 'Di',
            wednesday: 'Mi',
            thursday: 'Do',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'So',
        },

        transportTypes: {
            0: 'Alle',
            1: 'Zug',
            3: 'Direktfahrt',
            4: 'Qualinight',
        },
    },

    // Form
    form: {
        address: {
            salutation: {
                company: 'Firma',
                male: 'Herr',
                female: 'Frau',
            },

            name: 'Name',
            firstname: 'Vorname',
            lastname: 'Nachname',
            company: 'Firma',
            street: 'Strasse',
            contact: 'Kontakt',
            phone: 'Telefon',
            location: 'PLZ/Ort',
            locationString: 'Ort',
            zip: 'PLZ',
            email: 'E-Mail',

            create: 'Erstellen',
            edit: 'Speichern',

            standardSenderAddress: 'Standard Absenderadresse',
            standardReceiverAddress: 'Standard Empfängeradresse',
            standardBillingAddress: 'Standard Rechnungsadresse',
            isBillingAddress: 'Rechnungsadresse',

            inactiveZipWarning: 'Die gewählte Adresse hat eine ungültige Postleitzahl (Postfach Postleitzahl), bitte geben sie eine gültige Postleitzahl ein.',
        },
    },

    notes: {
        charsLeft: '({0} Zeichen übrig)',
    },

    // Views
    login: {
        title: 'Login',
        mainTitle: 'Willkommen im<br>Kundenportal',
        footer: '<strong>swissconnect</strong><br>Schnellster und nachhaltigster<br>Kurierdienst der Schweiz.',
    },

    goodbye: {
        title: 'Auf Wiedersehen',
    },

    passwordReset: {
        title: 'Passwort zurücksetzen',
    },

    passwordResetSetPassword: {
        title: 'Passwort zurücksetzen',
    },

    registerThankYou: {
        mainTitle: 'Registrierung',
        title: 'Registrierung abgeschlossen',
        text: 'Ihre Registrierung wurde abgeschlossen, Sie können sich jetzt anmelden.',
        loginButton: 'Zur Anmeldung',
    },

    register: {
        title: 'Registrierung',
        mainTitle: 'Registrierung',
        footer: '<strong>swissconnect</strong><br>Schnellster und nachhaltigster<br>Kurierdienst der Schweiz.',

        navigation: {
            user: 'Benutzer',
            addresses: 'Adressen',
            payment: 'Zahlungsdaten',
        },

        form: {
            username: 'Benutzername',
            password: 'Passwort',
            passwordRepeat: 'Passwort wiederholen',
            newsletter: 'Ich möchte den swissconnect Newsletter erhalten',
            tosAccepted: 'Ich akzeptiere die <a href="{url}" target="_blank">allgemeinen Geschäfts&shy;bedingungen und Datenschutz&shy;bestimmungen.</a>*',
            addDefaultSenderAddress: 'Standard Abholadresse',
            addDefaultReceiverAddress: 'Standard Lieferadresse',
            invoiceAddressTitle: 'Rechnungsadresse',
            invoiceDispatchTypeTitle: 'Zustellung',
            invoiceDispatchDisclaimer: 'Der Umwelt zuliebe setzen wir auf PDF-Rechnungen. Für Papierrechnungen verrechnen wir 5.-',
            invoiceTypeTitle: 'Abrechnungsart',

            invoiceMail: 'E-Mail',
            invoiceDispatchType: {
                email: 'E-Mail',
                post: 'Post',
            },

            invoiceType: {
                monthly: 'Monatsrechnung (Sammelabrechnung)',
                single: 'Einzelabrechnung pro Auftrag',
            },

            languageLabel: 'Rechnungssprache',
            language: {
                de: 'Deutsch',
                fr: 'Französisch',
                it: 'Italienisch',
                en: 'Englisch',
            },
        },
    },

    profile: {
        title: 'Profil bearbeiten',

        sender: 'Absenderadresse',
        receiver: 'Empfängeradresse',
        invoice: 'Rechnungsadresse',

        setAddress: 'Standard-Adresse setzen',
        changeAddress: 'Standard-Adresse ändern',

        changeOnRequest: {
            title: 'Zustellung/Abrechnung',
            email: 'E-Mail',
            deliveryType: {
                label: 'Zustellungsart',
                post: 'Post',
                email: 'E-Mail',
            },

            invoiceType: {
                label: 'Abrechnungsart',
                monthly: 'Monatsrechnung (Sammelrechnung)',
                single: 'Einzelabrechnung',
            },
        },

        changePassword: 'Passwort ändern',
    },

    offer: {
        title: 'Offerte bestellen',
    },

    offerThankYou: {
        title: 'Vielen Dank',
        text: 'Ihre Offerte ist bei uns eingegangen.',
    },

    order: {
        title: 'Kurier bestellen',
    },

    orderThankYou: {
        title: 'Vielen Dank für Ihre Bestellung.',
        text: 'In wenigen Augenblicken wird der Lieferschein an <a href="mailto:{mail}">{mail}</a> versendet. Wir bitten Sie, diesen auszudrucken und dem Kurier bei der Abholung mitzugeben.',
        contact: 'Bei Fragen kontaktieren Sie uns unter 041 227 20 00.',
        orderButton: 'weitere Bestellung',
    },

    orderConfirm: {
        title: 'Ihr Auftrag',
    },

    archive: {
        title: 'Archiv',
        empty: 'Keine Treffer gefunden.',
        orderAgain: 'Erneut bestellen',
        orderAgainHint: 'Erneut bestellen',
    },

    active: {
        title: 'Sendungen',
        empty: 'Keine Treffer gefunden',
    },

    addressBook: {
        title: 'Adressbuch',
        empty: 'Keine Adressen gefunden',
    },

    createAddress: {
        title: 'Neuer Kontakt',
    },

    editAddress: {
        title: 'Kontakt bearbeiten',
    },

    passwordChange: {
        title: 'Passwort ändern',
    },

    recurringShipments: {
        title: 'Daueraufträge',
        empty: 'Keine Daueraufträge gefunden.',
    },

    // Steps
    registerUserStep: {
        title: 'Benutzer',
    },

    registerAddressesStep: {
        title: 'Adressen',
    },

    registerPaymentStep: {
        title: 'Rechnungsdaten',
    },

    // Components
    goodbyeMessage: {
        title: 'Auf Wiedersehen!',
    },

    pickupTimeDisclaimer: {
        title: 'Anpassung Abholzeitpunkt',
        text: 'Ihr gewünschter Abholzeitpunkt liegt in der Vergangeheit und wurde automatisch auf <strong>{time} Uhr</strong> korrigiert. Bitte bestätigen Sie Ihre Bestellung erneut mit einem Klick auf "bestellen".',
    },

    podEmailForm: {
        email: 'E-Mail Adresse',
        button: 'POD anfordern',
        message: 'POD-Mail wurde versendet.',
    },

    newVersionMessage: {
        title: 'Die Benutzeroberfläche wurde erneuert.',
        description: ' Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
        button: 'Seite neu laden',
    },

    welcomeMessage: {
        welcome: 'Willkommen, {user}!',
    },

    passwordChangeForm: {
        password: 'Aktuelles Passwort',
        newPassword: 'Neues Passwort',
        newPasswordRepeat: 'Passwort wiederholen',

        hint: 'Nach erfolgreicher Änderung des Passwortes müssen Sie sich erneut anmelden.',
        error: 'Ein Fehler ist aufgetreten. Bitte überprüfen Sie ihr Passwort',

        button: 'Passwort ändern',
    },

    passwordResetForm: {
        username: 'Benutzername oder E-Mail',
        button: 'Passwort zurücksetzen',
        confirmation: 'Ein Bestätigungslink wurde per E-Mail versandt.',
    },

    passwordResetSetPasswordForm: {
        password: 'Passwort',
        passwordRepeat: 'Passwort wiederholen',
        button: 'Passwort zurücksetzen',
        confirmation: 'Passwort wurde zurückgesetzt',
        error: 'Ein Fehler ist aufgetreten.',
        loginButton: 'Jetzt anmelden',
    },

    export: {
        button: 'Ausgewählte exportieren',
        buttonAll: 'Alle exportieren',

        externalNumber: 'Auftragsnummer',
        reference: 'Referenz',

        hint: 'Mehr als 1000 Sendungen können nicht auf einmal exportiert werden.<br>Bitte Filtern Sie die Auswahl.',
    },

    dashboard: {
        title: 'Übersicht',
        sortButton: 'Dashboard bearbeiten',
        saveButton: 'Sortierung speichern',
    },

    profileForm: {
        sections: {
            user: 'Benutzer',
            address: 'Adresse',
        },

        form: {
            username: 'Benutzername',
            email: 'E-Mail Adresse',
            newsletter: 'Ich möchte den Newsletter abonnieren',
        },

        submit: 'Speichern',
        saveMessage: 'Profil wurde erfolgreich gespeichert.',
    },

    footer: {
        copyright: '© {year} swissconnect — All rights reserved',
        address: 'swissconnect ag<br>Güterstrasse 7<br>6002 Luzern',

        order: 'Kurier bestellen',
        offer: 'Preise & Offerte',
        services: 'Angebot',
        faq: 'Häufige Fragen / FAQ',
        tos: 'AGB & Datenschutz',
        tosFull: 'AGB & Datenschutz',
        contact: 'Kontakt',
        imprint: 'Impressum',
        newsletter: 'Sie möchten wissen, was in der Schweizer Express­logistik­branche passiert?',
        newsletterButton: 'Newsletter abonnieren',
    },

    header: {
        tel: '041 227 20 00',
        faq: 'Hilfe / FAQ',
    },

    loginForm: {
        username: 'E-Mail Adresse',
        password: 'Passwort',
        button: 'Anmelden',
        registerButton: 'Registrieren',
        error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut',
        forgotPassword: 'Passwort vergessen',
    },

    navigation: {
        dashboard: 'Übersicht',
        active: 'Aktuelle Sendungen',
        order: 'Kurier bestellen',
        offer: 'Offerte bestellen',
        priceCalculator: 'Preisrechner',
        archive: 'Archiv',
        addressBook: 'Adressbuch',
        createAddress: 'Kontakt erfassen',
        recurringOrders: 'Daueraufträge',
    },

    languageSwitcher: {
        label: 'Sprache auswählen',
        lang: {
            de: 'DE',
            en: 'EN',
            fr: 'FR',
            it: 'IT',
            master: '\uD83C\uDFD8',
        },
    },

    avatar: {
        profile: 'Profil bearbeiten',
        logout: 'Abmelden',
    },

    filter: {
        button: 'Filter',
        title: 'Filter',
        apply: 'Filter anwenden',
        reset: 'Filter zurücksetzen',
        from: 'Ab',
        to: 'Bis',
        addressId: 'Adress-ID',
        location: 'PLZ/Ort',
        reference: 'Referenz',
        number: 'Auftragsnummer',
        sender: 'Abholort',
        senderZip: 'PLZ',
        senderLocation: 'Ort',
        receiver: 'Zustellort',
        receiverZip: 'PLZ',
        receiverLocation: 'Ort',
        transportType: 'Transportart',
        transportTypes: {
            0: 'Alle',
            1: 'Zug',
            3: 'Direktfahrt',
            4: 'Qualinight',
        },
        state: 'Status',
        company: 'Firma/Name',
        street: 'Strasse',
        days: 'Wochentag',
        pickupDate: 'Datum',
    },

    address: {
        id: 'ID',
        name: 'Firma/Name',
        names: 'Vorname/Nachname',
        street: 'Strasse',
        contact: 'Kontakt',
        phone: 'Telefon',
        location: 'PLZ/Ort',
        locationString: 'Ort',
        zip: 'PLZ',
        company: 'Firma',
        male: 'Herr',
        female: 'Frau',
        saved: 'Gespeicherte Adresse',
        save: 'Adresse speichern',
        saveInfo: 'Wird im Adressbuch gespeichert',
        firstname: 'Vorname',
        lastname: 'Nachname',
        languageLabel: 'Rechnungssprache',
        language: {
            de: 'Deutsch',
            fr: 'Französisch',
            it: 'Italienisch',
            en: 'Englisch',
        },

        isStandardSenderAddress: 'Standard Absenderadresse',
        isStandardReceiverAddress: 'Standard Empfängeradresse',
        isStandardBillingAddress: 'Standard Rechnungsadresse',
    },

    popupShipmentForm: {
        reference: 'Referenz',
        numberOfColis: 'Anzahl Sendungsstücke',
        button: 'Änderungen speichern',
        confirmation: 'Ihre Eingaben wurden gespeichert',
        confirmationColisChanges: 'Die Anzahl wurde verändert.<br>Bitte drucken Sie die Frachtpapiere erneut aus,<br>und verwenden Sie nur den aktuellen.',
    },

    popupRecurringShipmentForm: {
        customerName: 'Name des Dauerauftrags',
        button: 'Bearbeiten',
        confirmation: 'Ihre Eingaben wurden gespeichert',
    },

    employee: {
        title: 'Haben Sie Fragen?',
    },

    locationSelect: {
        indicator: 'Nach Postleitzahl oder Ort suchen...',
    },

    shipment: {
        number: 'Nummer',
        date: 'Datum',
        state: 'Status',
        reference: 'Referenz',
        from: 'Abholort',
        to: 'Zustellort',
        cargo: 'Transportgut',
        colis: 'Sendungsstücke',
        totalWeight: 'Gesamtgewicht: {weight} kg',
        information: 'Information',
        sender: 'Absender',
        receiver: 'Empfänger',
        downloadFreightDocuments: 'Frachtpapiere',
        downloadDeliveryNotes: 'Lieferschein',
        priceInclVat: 'Preis inkl. MwSt.: CHF {price}',
        priceExclVat: 'Preis exkl. MwSt.: CHF {price}',
        visierName: 'Visiert von',
        cancelled: 'Annulliert',
        pickedUp: 'Abgeholt: {date} um {time}',
        plannedPickup: 'Geplante Abholung: {from} - {to}',
        delivered: 'Zugestellt: {date} um {time}',
        plannedDelivery: 'Geplante Zustellung: {from} - {to}',
        deliveryDate: 'Datum Zustellung',
        numberOfColis: 'Anzahl Sendungsstücke',
        times: 'Zeiten',
        prices: 'Preis',
        recurrences: 'Ausführung',
        transportType: 'Transportart',
        days: 'Ausführungen',
    },

    pagination: {
        info: 'Eintrag {start} - {end} von {total}',
        perPage: 'Einträge pro Seite',
        next: 'weiter',
        prev: 'zurück',
    },

    trackShipment: {
        title: 'Sendung verfolgen',
        placeholder: 'Sendungsnummer',
        button: 'Verfolgen',
        notFound: 'Kein Sendung mit dieser Nummer gefunden.',
        numberTitle: 'Sendungs-Nummer',
        pickup: 'Abholung',
        pickedUp: 'Abgeholt: {date} um {time}',
        expectedPickUp: 'Voraussichtliche Abholung: {date} bis {time}',
        delivery: 'Zustellung',
        delivered: 'Zugestellt: {date} um {time}',
        cancelled: 'Annulliert',
        expectedDelivery: 'Voraussichtliche Zustellung: {date} bis {time}',
        expectedPickup: 'Voraussichtliche Abholung: {date} ab {time}',
        to: 'an {name}',
        date: '{date} bis {time}',
        states: {
            created: 'In Vorbereitung',
            pickedUp: 'Abgeholt',
            inDelivery: 'In Zustellung',
            delivered: 'Persönlich zugestellt',
        },
    },

    offerForm: {
        disclaimer: 'Preise und Angaben ohne Gewähr. Zuschläge für Wartezeit usw. gemäss <a href="{url}" target="_blank">AGB</a> möglich.',
        button: 'Offerte anfordern',

        sections: {
            pickup: 'Abholort',
            delivery: 'Zustellort',
            notes: 'Allgemeine Bemerkung',
            invoice: 'Rechnung',
        },

        invoice: {
            invoiceMail: 'E-Mail Rechnungsempfänger',
            reference: 'Referenz auf Rechnung',
            typeMonthly: 'Monatsrechnung (Sammelabrechnung)',
            typeSingle: 'Einzelabrechnung pro Auftrag',
        },

        date: 'Datum',
        time: 'Zeit',
    },

    orderForm: {
        sections: {
            pickup: 'Abholort',
            delivery: 'Zustellort',
            notes: 'Allgemeine Bemerkung',
            invoice: 'Rechnung',
        },

        invoice: {
            reference: 'Referenz auf Rechnung',
            typeMonthly: 'Monatsrechnung (Sammelabrechnung)',
            typeSingle: 'Einzelabrechnung pro Auftrag',
        },

        invoiceMail: 'E-Mail Rechnungsempfänger',
        emailClient: 'E-Mail Auftraggeber',
        emailLetter: 'E-Mail für Frachtpapiere',
        syncEmailLetter: 'Gilt auch für Frachtpapiere',

        date: 'Datum',
        time: 'Zeit',

        button: 'weiter',
        confirmButton: 'bestellen',
    },

    orderConfirmForm: {
        back: 'zurück',
        submit: 'bestellen',

        order: {
            title: 'Ihre Sendung',
            date: 'Versanddatum',
            time: 'Abholen ab',
        },

        coli: {
            title: 'Informationen zur Sendung',
            count: 'Anzahl',
            mass: 'Mass',
            cm: 'cm',
            kg: 'kg',
        },

        notes: {
            title: 'Bemerkungen',
            empty: 'n/A',
        },

        sender: {
            title: 'Abholort',
        },

        receiver: {
            title: 'Zustellort',
        },

        invoice: {
            title: 'Rechnungsadresse',
        },

        tos: {
            accept: 'Ich akzeptiere die <a href="{url}" target="_blank">allgemeinen Geschäftsbedingungen</a>',
        },

        address: {
            name: 'Name',
            company: 'Firma',
            contact: 'Kontakt',
            street: 'Adresse',
            location: 'PLZ/Ort',
            phone: 'Telefon',
            email: 'E-Mail Rechnungsempfänger',
            invoiceType: {
                label: 'Abrechnungsart',
                monthly: 'Monatsrechnung (Sammelrechnung)',
                single: 'Einzelabrechnung',
            },
            reference: {
                monthly: 'Auftragsreferenz',
                single: 'Referenz auf Rechnung',
            },
        },
    },

    priceCalculator: {
        title: 'Preis und Lieferzeit berechnen',
        loader: 'Preise werden berechnet',
        button: 'Jetzt bestellen',
        duration: {
            title: 'Maximale Lieferdauer',
            description: '',
            label_title: 'Voraussichtlicher Liefertermin',
            label: 'Heute 18:00 Uhr falls bis {lastOrderTime} Uhr bereit',
        },
        co2: {
            title: 'CO₂-Ersparnis',
            description: 'Die Natur wird es Ihnen danken! Durch unser einzigartiges Transportsystem sparen Sie ~{co2}kg CO₂ ein.',
            value: '~{co2}kg',
        },
        form: {
            from: 'Von',
            to: 'Bis',
            submit: 'Preis berechnen',
            date: 'Datum',
            time: 'Zeit',
            orderButton: 'Jetzt bestellen',
        },
        price: {
            title: 'Preis',
            tax_included: 'inkl. MwSt',
            label: 'Preis ohne Gewähr - Preis nach Volumen - (IATA-) und effektive Gewicht, der höhere Wert ist Preisgrundlage',
        },
        error: {
            weight: 'Aufgrund des Gewichts oder der Dimensionen ist eine Online-Preisberechnung nicht möglich.<br>Bitte füllen Sie das <a href="{url}">Offertenformular</a> aus.',
            noresult: 'Es konnten keine Preisinformationen ermittelt werden.<br>Bitte füllen Sie das <a href="{url}">Offertenformular</a> aus.',
        },
        tariffs: '(zur <a href="{url}" tabindex="-1" target="_blank">Tarifliste</a>)',
    },

    coliList: {
        title: 'Sendungsstücke',
        notes: 'Beschreibung',
        count: 'Anzahl Stücke',
        palette: 'Paletten',
        mass: 'Masse',
        cm: 'cm',
        kg: 'kg',
        iata_help: 'Sollte das berechnete {0} höher sein als das effektive Gewicht, gilt dieses für die Tarifberechnung.',
        iata_help_page: 'Volumengewicht',
        form: {
            height: 'Höhe',
            width: 'Breite',
            length: 'Länge',
            weight: 'Gewicht',
            iata_weight: 'IATA-Gewicht',
        },
        add: 'Hinzufügen',
        remove: 'Entfernen',
    },

    trackPopup: {
        title: 'Sendung verfolgen',
        button: 'Sendung verfolgen',
    },

    addressSelect: {
        indicator: 'Keine Adressen gefunden',
        reset: 'Feld zurücksetzen',
    },

    orderResult: {
        duration: 'maximale Dauer',
        duration_value: '{duration}',
        estimate: 'Voraussichtliche Lieferzeit',
        estimate_value: '{start} bis {end}',
        co2: 'CO₂-Ersparnis',
        co2_value: '~{co2}kg',
        co2_description: 'Die Natur wird es Ihnen danken! Durch unser einzigartiges Transportsystem sparen Sie ~{co2}kg CO₂ ein.',
        price: 'Preis',
        vat: 'inkl. MwSt.',
        help: 'Von Montag-Freitag, 08:00 bis 18:00 Uhr bearbeiten wir Ihren Auftrag umgehend. In der übrigen Zeit und am Wochenende ist die Abwicklung von Sendungen nur beschränkt möglich. Erkundigen Sie sich bitte unter der Nummer +41 41 227 20 00 nach den Möglichkeiten.',
    },

    // Widgets
    widget: {
        addresses: {
            title: 'Adressbuch',
            link: 'Alle Kontakte anzeigen',
            empty: 'Keine Kontakte gefunden',
        },

        archive: {
            title: 'Archiv',
            link: 'Archiv anzeigen',
            empty: 'Keine Sendungen gefunden',
            export: 'Sendungen exportieren',

            externalNumber: 'Auftragsnummer',
            delivered: 'Ausgeliefert',
            visiertVon: 'Visiert von',
        },

        recurringShipments: {
            title: 'Daueraufträge',
            link: 'Daueraufträge anzeigen',
            empty: 'Keine Daueraufträge gefunden',
            export: 'Daueraufträge exportieren',

            sender: 'Absender',
            receiver: 'Empfänger',
            days: 'Wochentage',
        },

        savings: {
            title: '{co2}-Einsparung',
            text: 'Sie haben bisher {savings} eingespart.',
            link: 'Mehr zum {co2}-Rechner',
        },

        active: {
            title: 'Sendungsüberblick {date}',
            inPreparation: 'In Vorbereitung',
            readyForPickup: 'Zur Abholung',
            pickedUp: 'Abgeholt',
            inTransit: 'In Zustellung',
            delivered: 'Zugestellt',
            shipments: 'Keine Sendungen vorhanden | 1 Sendung | {count} Sendungen',
            link: 'Alle aktuellen Sendungen anzeigen',
        },

        priceCalculator: {
            title: 'Preis berechnen',
            button: 'Zum Preisrechner',
            from: 'Von',
            to: 'Nach',
        },

        tracking: {
            title: 'Sendung verfolgen',
        },

        switchUser: {
            title: 'Benutzer wechseln',
            indicator: 'Bitte überprüfen Sie Ihre Suchanfrage',
            button: 'Benutzer wechseln',
            resetButton: 'Benutzerwechsel beenden',
            warning: 'Sie sind im Moment als <strong>{user}</strong> angemeldet!',
        },

        quickLinks: {
            order: {
                label: 'Neue Sendung',
            },
            offer: {
                label: 'Offerte bestellen',
            },
            profile: {
                label: 'Profil bearbeiten',
            },
            faq: {
                label: 'Zu den FAQ',
                link: 'https://swissconnect.ch/de/haeufige-fragen-faq',
            },
            blog: {
                label: 'Unser Blog',
                link: 'https://swissconnect.ch/de/ueber-uns/blog',
            },
            team: {
                label: 'Unser Team',
                link: 'https://swissconnect.ch/de/ueber-uns/unternehmen/mitarbeitende',
            },
            prices: {
                label: 'Tarifliste',
                link: 'https://swissconnect.ch/de/preise-offerte/tarifliste',
            },
        },
    },

    // Validation
    validation: {
        required: 'Dieses Feld ist ein Pflichtfeld',
        required_id: 'Dieses Feld ist ein Pflichtfeld',
        min_value: 'Dieses Feld darf den Wert von {min} nicht unterschreiten',
        max_value: 'Dieses Feld darf den Wert von {max} nicht überschreiten',
        regex: 'Ungültiger Wert',
        email: 'Ungültige E-Mail Adresse',
        max_weight: 'Aufgrund des Gewichts oder der Dimensionen ist eine Online-Preisberechnung nicht möglich. Bitte füllen Sie das Offertenformular aus.',
        noresult: 'Es konnten keine Preisinformationen ermittelt werden. Bitte füllen Sie das Offertenformular aus.',
        time: 'Die Abholzeit liegt in der Vergangenheit.',
        delivery_time_train: 'Die Auslieferzeit ist voraussichtlich nach 18:00 Uhr. Bitte füllen Sie das Offertenformular aus.',
        delivery_time_direct: 'Die Auslieferzeit ist voraussichtlich nach {max_delivery_time_direct} Uhr. Bitte füllen Sie das Offertenformular aus.',
        weekend: 'Die Abholzeit ist an einem Wochenende. Bitte füllen Sie das Offertenformular aus.',
        holiday: 'Die Abholzeit fällt auf einen nationalen Feiertag. Bitte füllen Sie das Offertenformular aus.',
        confirmed: 'Passwörter müssen übereinstimmen',
        uniqueUsername: 'Dieser Benutzername wird bereits verwendet',
        uniqueEmail: 'Diese E-Mail Adresse wird bereits verwendet',
    },

    // Links
    links: {
        website: 'https://swissconnect.ch',
        telto: 'tel:0041412272000',
        mailto: 'mailto:info@swissconnect.ch',

        services: 'https://swissconnect.ch/de/angebot',
        faq: 'https://swissconnect.ch/de/haeufige-fragen-faq',
        tos: 'https://swissconnect.ch/de/agb-datenschutz',
        imprint: 'https://swissconnect.ch/de/impressum',
        contact: 'https://swissconnect.ch/de/kontakt',
        order: 'https://swissconnect.ch/de/kurier-bestellen#/order',

        iso: 'https://swissconnect.ch/de/warum-wir/qualitaet',
        climate: 'https://swissconnect.ch/de/warum-wir/nachhaltigkeit',

        newsletter: 'https://swissconnect.ch/de/newsletter-abonnieren',

        iata: 'https://swissconnect.ch',
        co2: 'https://swissconnect.ch',

        tariffs: 'https://swissconnect.ch',

        changeRequestMailTo: 'mailto:info@swissconnect.ch',
    },
};
